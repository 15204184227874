<template>
  <div>
    <button type="button" class="button_outline" @click="modal = true">
      Adicionar cidade
    </button>
    <modal :modal="modal" @changeModal="e => (modal = e)">
      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">
            Adicionar nova cidade para coleta
          </h2>
        </div>

        <div class="modal__form--group">
          <div class="col-12 alert" style="margin-bottom: 1.25rem;">
            <i class="fa fa-exclamation-circle"></i>
            Cadastro de cidades liberados mediante aprovação.
          </div>

          <r-select
            label="Estado"
            v-model="form.province_id"
            :items.sync="provinces"
            labelName="name"
            placeholder="Selecionar estado"
            class="col-12"
          />

          <r-select
            label="Cidade"
            v-model="form.city_id"
            :items.sync="cities"
            :readonly="!form.province_id || !cities.length"
            labelName="name"
            class="col-12"
            :placeholder="
              !form.province_id ? 'Selecione um estado' : 'Selecionar cidade'
            "
          />

          <r-input
            v-model="form.minimal_amount"
            :mask="[
              '#.##',
              '##.##',
              '###.##',
              '####.##',
              '#####.##',
              '######.##'
            ]"
            label="Quantidade mínima"
          />

          <r-input type="money" v-model="form.value" label="Valor" />

          <div class="footer_modal">
            <button
              :disabled="saving"
              type="button"
              @click.prevent="onSave()"
              class="button_outline"
            >
              {{ saving ? 'Salvando...' : 'Cadastrar' }}
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import swal from 'sweetalert'

const defaultForm = {
  item_id: '',
  city_id: null,
  province_id: null,
  value: 0,
  have_destination: false,
  recyclable: [],
  unity_id: '',
  minimal_amount: '',
  destionation_certification: ''
}

export default {
  props: ['item_id', 'unity_id'],
  data: () => ({
    modal: false,
    saving: false,
    form: { ...defaultForm },
    cities: []
  }),
  computed: {
    ...mapGetters('province', ['provinces']),
    valid () {
      const form = this.form

      if (!form.item_id) return false
      if (!form.city_id) return false
      if (!form.value) return false
      if (!form.unity_id) return false
      if (!form.minimal_amount) return false
      if (form.have_destination && !form.destionation_certification) {
        return false
      }

      return true
    }
  },
  methods: {
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    ...mapActions('material', { save: 'save' }),
    initForm () {
      this.form = {
        ...defaultForm,
        item_id: this.item_id,
        unity_id: this.unity_id
      }
    },
    async onSave () {
      if (!this.valid) return this.alert('validation')
      this.saving = true
      const data = new FormData()

      Object.keys(this.form).map(key => {
        const item = this.form[key]
        if (!Array.isArray(item)) data.append(key, item)
        else {
          item.map(i => data.append(`${key}[]`, i))
        }
      })

      const { status, response } = await this.save(data)
      this.alert(status ? 'success' : 'error', response.message)
      if (status) {
        this.modal = false
        this.$emit('saved', true)
      }
      this.saving = false
    },
    alert (type = 'success', text = 'Verifique todos os campos.') {
      swal({
        title: type === 'success' ? 'Sucesso!' : 'Ooops!',
        icon: type === 'success' ? 'success' : 'error',
        text,
        button: type === 'success' ? null : 'Ok',
        timer: type === 'success' ? 1250 : 3000
      })
    }
  },
  mounted () {
    this.fetchProvinces()
  },

  components: {
    Modal: () => import('../../components/modal.vue'),
    RSelect: () => import('@/components/select.vue'),
    RInput: () => import('@/components/input.vue')
  },
  watch: {
    async 'form.province_id' (val) {
      this.cities = []
      if (!val) return
      const url = `cities?province_id=${val}`
      const { data } = await this.$http.get(url)
      this.cities = data
      if (!this.cities.map(i => i.id).includes(this.form.city_id)) {
        this.form.city_id = ''
      }
    },
    value (val) {
      if (!val) this.form = { ...defaultForm }
    },
    editing (val) {
      if (!Object.keys(val).length) return this.$emit('input', false)
      const { recyclable, ...args } = val
      Object.assign(this.form, defaultForm, args)
      setTimeout(() => (this.form.recyclable = recyclable), 1)
    },
    modal (val) {
      if (!val) this.form = { ...defaultForm }
      else this.initForm()
    }
  }
}
</script>
