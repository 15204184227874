var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"button_outline",attrs:{"type":"button"},on:{"click":function($event){_vm.modal = true}}},[_vm._v(" Adicionar cidade ")]),_c('modal',{attrs:{"modal":_vm.modal},on:{"changeModal":e => (_vm.modal = e)}},[_c('div',{staticClass:"modal__content"},[_c('div',{staticClass:"modal__header"},[_c('h2',{staticClass:"modal__title"},[_vm._v(" Adicionar nova cidade para coleta ")])]),_c('div',{staticClass:"modal__form--group"},[_c('div',{staticClass:"col-12 alert",staticStyle:{"margin-bottom":"1.25rem"}},[_c('i',{staticClass:"fa fa-exclamation-circle"}),_vm._v(" Cadastro de cidades liberados mediante aprovação. ")]),_c('r-select',{staticClass:"col-12",attrs:{"label":"Estado","items":_vm.provinces,"labelName":"name","placeholder":"Selecionar estado"},on:{"update:items":function($event){_vm.provinces=$event}},model:{value:(_vm.form.province_id),callback:function ($$v) {_vm.$set(_vm.form, "province_id", $$v)},expression:"form.province_id"}}),_c('r-select',{staticClass:"col-12",attrs:{"label":"Cidade","items":_vm.cities,"readonly":!_vm.form.province_id || !_vm.cities.length,"labelName":"name","placeholder":!_vm.form.province_id ? 'Selecione um estado' : 'Selecionar cidade'},on:{"update:items":function($event){_vm.cities=$event}},model:{value:(_vm.form.city_id),callback:function ($$v) {_vm.$set(_vm.form, "city_id", $$v)},expression:"form.city_id"}}),_c('r-input',{attrs:{"mask":[
            '#.##',
            '##.##',
            '###.##',
            '####.##',
            '#####.##',
            '######.##'
          ],"label":"Quantidade mínima"},model:{value:(_vm.form.minimal_amount),callback:function ($$v) {_vm.$set(_vm.form, "minimal_amount", $$v)},expression:"form.minimal_amount"}}),_c('r-input',{attrs:{"type":"money","label":"Valor"},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}),_c('div',{staticClass:"footer_modal"},[_c('button',{staticClass:"button_outline",attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.onSave()}}},[_vm._v(" "+_vm._s(_vm.saving ? 'Salvando...' : 'Cadastrar')+" ")])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }